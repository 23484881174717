import * as THREE from "three/build/three.module"

var _o2 = null;
export class instanceMgr{
    constructor(){
        _o2 = document.o2;
        this.mapInstance={};
        this.arrInstance = [];
        this.instanceNode = new THREE.Group();
        this.instanceNode.name = "instanceNode";
        _o2.frame_move_recall.push(this.onFrame.bind(this));
    }
    create(obj){
        let ins = null;
        if(!this.mapInstance[obj.uuid]){
            ins = {};
            this.mapInstance[obj.uuid] = ins;
            this.arrInstance.push(ins);
            ins.arrBaseMat = [];
            ins.arrInsMesh = [];
            ins.arrObj = [];
            let arrBaseMesh = [];
            obj.updateMatrixWorld();
            let pos = obj.getWorldPosition();
            obj.traverse(e=>{
                if(e.type == "Mesh"){
                    e.updateMatrixWorld();
                    arrBaseMesh.push(e);
                    let mat = new THREE.Matrix4();
                    mat.setPosition(-pos.x,-pos.y,-pos.z);
                    mat.multiply(e.matrixWorld);
                    ins.arrBaseMat.push(mat);
                }
            })
            arrBaseMesh.forEach(e => {
                let insMesh = new THREE.InstancedMesh(e.geometry,e.material,10000);
                insMesh.count = 1;
                console.log(insMesh);
                insMesh.instanceMatrix.setUsage(THREE.DynamicDrawUsage);
                insMesh.instanceMatrix.needsUpdate = true;
                ins.arrInsMesh.push(insMesh);
                this.instanceNode.add(insMesh);
            });
            
        }else{
            ins = this.mapInstance[obj.uuid];
            ins.arrInsMesh.forEach(e=>{
                e.count = e.count + 1;
            })
        }
        let newObj = new THREE.Object3D();
        newObj.instanceId = obj.uuid;
        newObj.matrixWorldNeedsUpdate = true;
        ins.arrObj.push(newObj);
        return newObj;
    }

    remove(obj){
        if(this.mapInstance[obj.instanceId]){
            let ins = this.mapInstance[obj.instanceId];
            let idx = ins.arrObj.indexOf(obj);
            if(idx >= 0){
                ins.arrObj.splice(idx,1);
                ins.arrInsMesh.forEach(e=>{
                    e.count = Math.max(0,e.count-1);
                })
            }
            this.instanceNode.remove(obj);
        }
    }

    // setVisible(obj,value){
    //     if(this.obj && this.mapInstance[obj.instanceId]){
    //         let ins = this.mapInstance[obj.instanceId];
    //         if(!value){
    //             let idx = ins.arrObj.indexOf(obj);
    //             ins.arrObj.splice(idx,1);
    //             ins.arrInsMesh.forEach(e=>{
    //                 e.count = Math.max(0,e.count-1);
    //             })
    //         }else{
    //             ins.arrObj.push(obj);
    //             ins.arrInsMesh.forEach(e=>{
    //                 e.count = e.count+1;
    //             })
    //         }
    //     }
    // }

    onFrame(t){
        if(this.arrInstance && this.arrInstance.length > 0){
            this.arrInstance.forEach(ins=>{
                for (let i = 0; i < ins.arrInsMesh.length; i++) {
                    let idx = 0;
                    let mesh = ins.arrInsMesh[i];
                    for (let j = 0; j < ins.arrObj.length; j++) {
                        let obj = ins.arrObj[j];
                        if(this.getVisible(obj)){
                            let mat = obj.matrixWorld.clone();
                            if(i < ins.arrBaseMat.length){
                                mat.multiply(ins.arrBaseMat[i]);
                            }
                            mesh.setMatrixAt(idx,mat);
                        }else{
                            idx--;
                        }
                        idx++;
                    }
                    mesh.count = idx;
                    if(mesh.count > 0){
                        mesh.instanceMatrix.needsUpdate = true;
                    } 
                }
            })
        }
    }
    getVisible(obj){
        let node = obj;
        while(node){
            if(!node.visible)return false;
            node = node.parent;
        }
        return true;
    }
} 