import { get, post } from "./request";

// export const register = (params) => get("/register.php", params);
// export const login = (params) => get("/login.php", params);
export const save = (params) => get("/save.php", params);

export const check_status = (params) => get("/check_status.php", params);//检查权限/change_status
export const change_status = (params) => get("/change_status.php", params);//检查权限/change_status

export const check_code = (params) => get("/check_code.php", params);//检查权限/check_code

export const client = (params) => get("/check_meetingroom.php", params);


export const register_user2 = (params) => get("/register_user2.php", params); //token注册


export const sendCode = (params) => get("/sms/send.php", params); //获取验证码

export const verify = (params) => get("/sms/verify.php", params); //验证码验证

export const register = (params) => get("/register.php", params); //手机号注册

export const login = (params) => get("/login.php", params); //手机号登录

export const bindMobile = (params) => get("/auth/bindMobile.php", params); //微信手机号绑定

export const setpsd = (params) => get("/auth/setpsd.php", params); //微信手机号绑定

export const miniappLogin = (params) => get("/auth/miniappLogin.php", params); //微信小程序登录

export const rename = (params) => get("/auth/rename.php", params); //微信小程序登录

export const get_avaters = (params) => get("/get_avaters.php", params); //获取在线人数


export const get_infos = p => get('/get_infos.php',p)

export const user_change = p => get('/user_change.php',p)


