import * as THREE from 'three/build/three.module';
import { FBXLoader } from 'three/examples//jsm/loaders/FBXLoader';
import JSZip from 'jszip';

const headWeight = require('../configs/vertexColor');
const baseBone = require('../configs/baseBone.json');

export const playerType = {
  normal : 0,
  visitor : 1,
  dummy : 2
}

class player {

  static initBaseBone;
  static dicPreb = {};
  static dicAccPreb = {};
  static dicHairPreb = {};
  static onModelLoad = {};
  static clips = {};
  static dicSitModel = {};
  static dicAnimModel = {};
  static loadQueue = {};
  static clips ={};

  constructor(info) {
    console.log("new player");
    this.o2 = document.o2;
    this.info ={};
    this.onStateChg = [];
    this.bLoad = true;
    this.target = new THREE.Vector3();
    this.bUpdateBone = true;
    if(info){
      if(!Number.isNaN(info.id))this.id = info.id;
      if(info.name)this.name = info.name;
      if(!Number.isNaN(info.vip))this.vip = info.vip;
      if(info.bLoad !== undefined)this.bLoad = info.bLoad;
      if(info.bFake !== undefined)this.bFake = info.bFake;
      //if(this.bLoad){
        this.preb;
        this.obj;
        this.role;
        this.modelType = 0;
        if(!Number.isNaN(parseInt(info.modelType))){
          this.modelType = parseInt(info.modelType);
        }
        this.dicAcc = {};
        //this.dicBone = {};
        //this.arrBone = [];
        this.mixer;
        //this.boneInverses = [];
        this.bBlink = false;
        this.blinkTime = 0;
        this.blinkDealy = 0;

        this.bSpeak = false;
        this.speakTime = 0;
        this.speakCount = 0;
        this.speakWeight = 0;
      //}
      //else{
      if(!this.bLoad) { 
        this.obj = new THREE.Group(this.id);
        this.dicAnimObj = {};
        this.obj.type = 'Group';
      //}
      }
    }
  }

  initPlayer(info,action){
    this.setPlayer(info,()=>{
      if(action)action(this);
    });
  }
  onFrame(t){
    if(!this.bLoad)return;
    if(this.bBlink && this.head){
      this.blinkTime += t;
      if(this.head.morphTargetInfluences){
        if(this.blinkTime >= this.blinkDealy){
          let time = this.blinkTime - this.blinkDealy;
          if(time > 0.3){
            this.blinkDealy = Math.random() * 2 + 3;
            this.blinkTime = 0;
            return;
          }
          time /= 0.3;
          this.head.morphTargetInfluences[0] = Math.abs(Math.sin(time*Math.PI));
          this.head.morphTargetInfluences[1] = Math.abs(Math.sin(time*Math.PI));
        }
      }
    }
    if(this.head && this.bSpeak){
      this.speakTime += t;
      if(this.head.morphTargetInfluences.length >=4){
        let temp = Math.floor(this.speakTime)+1;
        if(temp != this.speakCount){
          this.speakCount = temp;
          this.speakWeight = Math.random() * 0.2 + 0.1;
        }
        let weight = this.speakWeight*Math.abs(Math.sin(this.speakTime*Math.PI*2));
        this.head.morphTargetInfluences[2] = weight;
        this.head.morphTargetInfluences[3] = Math.min(0.7, weight * 3);
      }
    }
    if(this.mixer && this.bUpdateBone){
      this.mixer.update(t);
    }
    // if(this.sit && this.bLoad){
    //   this.obj.rotation.y = this.sitAngle;
    // }
  }

  addAnimator(oldRole,newRole){
    if(this.mixer){
      this.mixer.stopAllAction();
      this.mixer.uncacheRoot(oldRole);
      this.mixer = undefined;
      this.curAction = undefined;
      this.lastAction = undefined;
    }
    this.mixer = new THREE.AnimationMixer(newRole);
    this.mixer.addEventListener("finished", e => {
      if(this.curAction && this.lastAction && 
        this.curAction != this.lastAction &&
        !this.curAction.clampWhenFinished){
        this.curAction.time = 0;
        this.curAction.enabled = true;
        this.curAction.play();
        this.lastAction.enabled = true;
        this.curAction.crossFadeTo(this.lastAction,0.2);
        this.lastAction.play();
        this.curAction = this.lastAction;
      }
    });
  }

  setPlayer(info,action){
    let scope = this;
    if(!info && action){
      action();
      return;
    }
    new Promise((resolve)=>{
      if(!Number.isNaN(info.modelIdx)){
        this.setModel(info.modelIdx,()=>{
          resolve();
        });
      }
      else{
        resolve();
      }
    }).then(()=>{
      if(scope.obj)scope.obj.visible = false;
      let loadCount = 0;
      let bloadFinish = false;
      function onLoadFinish(){
        loadCount--;
        console.log(loadCount);
        if(loadCount <= 0 && !bloadFinish){
          bloadFinish = true;
          setTimeout(() => {
            console.log(scope)
            if(scope.obj)scope.obj.visible = true;
            if(!scope.namePanel && scope.name){
              scope.add_lable_to_char(scope.name);
            }
            if(action)action(scope);
          }, 500);
        }
      }
      // if(info.hairIdx != undefined && this.role && this.bLoad){
      //   this.role.visible = false;
      // }
      if(!Number.isNaN(info.sex)){
        this.info.sex = info.sex;
      }
      if(info.hairColor){
        this.setHairColor(info.hairColor);
      }
      if(info.bodyColor){
        this.setBodyColor(info.bodyColor);
      }
      if(info.faceColor){
        this.setFaceColor(info.faceColor);
      }
      
      if(info.accInfo){
        loadCount++;
        let accCount = Object.keys(info.accInfo).length;
        for (const key in info.accInfo) {
          let accIdx = info.accInfo[key];
          this.setAcc(accIdx,key,()=>{
            accCount--;
            if(accCount == 0)onLoadFinish();
          });
        }
      }
      if(info.hairIdx != undefined){
        loadCount++;
        this.setHair(info.hairIdx,()=>{
          onLoadFinish();
        });
      }
      if(info.md5){
        loadCount++;
        if(!info.faceUrl){
          this.setAvatarHead(info.md5,null,null,()=>{
            onLoadFinish();
          });
        }
        else{
          this.setAvatarHead(info.md5,null,info.faceUrl,()=>{
            onLoadFinish();
          });
        }
      }
      if(loadCount == 0){
        onLoadFinish();
      }
    })
  }

  resetPlayer(){
    let scope = this;
    return new Promise((res,rej)=>{
      let info = this.info;
      this.info = {};
      //if(this.namePanel)this.namePanel.visible = true;
      this.setPlayer(info,()=>{
        scope.bLoaded = true;
        res();
      });
    })
  }
  
  setBlink(value){
    if(this.head && this.bLoad){
      this.head.material.morphTargets = true;
      if(value != this.bBlink) this.blinkTime = 0;
      this.bBlink = value;
      this.blinkDealy = Math.random() * 2 + 3;
    }
  }

  setSpeak(value){
    if(this.head && this.bLoad){
      this.head.material.morphTargets = true;
      if(value != this.bSpeak){
        this.bSpeak = value;
        this.speakTime = 0;
        this.speakCount = 0;
        this.speakWeight = 0;
        if(!value &&this.head.morphTargetInfluences.length >=4){
          this.head.morphTargetInfluences[2] = 0;
          this.head.morphTargetInfluences[3] = 0;
        }
      }
    }
  }

  setModel(idx,action){
    new Promise((resolve,reject)=>{
      if(idx != this.info.modelIdx && !Number.isNaN(idx)){
        console.log('setModel:',idx);
        this.info.modelIdx = idx;
        if(this.bLoad) this._setModel(idx,()=>{
          resolve();
        });
        else{
          for (const key in this.dicAnimObj) {
            let animObj = this.dicAnimObj[key];
            if(animObj && animObj.parent){
              animObj.parent.remove(animObj);
            }
            this.dicAnimObj[key] = null;
          }
          resolve();
        } 
      }
      else {
        resolve();
      }
    }).then(()=>{
      //this.setSit(this.sit);
      if(this.state){
        let state = this.state;
        this.state = null;
        this.setState(state);
      }
      if(action)action();
    })
  }

  setModelType(type,action){
    if(type != this.modelType){
      this.modelType = type;
      if(this.bLoad) this._setModel(this.info.modelIdx,action);
    }
  }

  setHair(idx,action){
    if(idx != this.info.hairIdx && !Number.isNaN(idx)){
      console.log('setHair:',idx);
      this.info.hairIdx = idx;
      if(this.bLoad) {
        this._setHair(idx,action);
        return;
      }
    }
    if(action)action();
  }

  setAcc(idx,type,action){
    if(!this.info.accInfo)this.info.accInfo = {};
    if(this.info.accInfo[type] != idx && !Number.isNaN(idx)){
      console.log('setAcc:',type,idx);
      this.info.accInfo[type] = idx;
      if(this.bLoad) {
        this._setAcc(idx,type,action);
        return;
      }
    }
    if(action)action();
  }
  setHairColor(rgb){
    if(this.info.hairColor != rgb && rgb != null){
      console.log('setHairColor:',rgb);
      this.info.hairColor = rgb;
      if(this.bLoad) this._setHairColor(rgb);
    }
  }

  setBodyColor(rgb){
    if(this.info.bodyColor != rgb && rgb != null){
      console.log('setBodyColor:',rgb);
      this.info.bodyColor = rgb;
      if(this.bLoad) this._setBodyColor(rgb);
    }
  }

  setFaceColor(rgb){
    if(this.info.faceColor != rgb && rgb != null){
      console.log('setBodyColor:',rgb);
      this.info.faceColor = rgb;
      if(this.bLoad) this._setFaceColor(rgb);
    }
  }
  setAvatarHead(md5,img,faceUrl,action){
    if(this.info.md5 != md5 && md5 != null){
      this.info.md5 = md5;
      if(faceUrl)this.info.faceUrl = faceUrl;
      if(this.bLoad) this._setAvatarHead(md5,img,faceUrl,action);
    }
  }

  add_lable_to_char(str,height=2200,color="#ffffff",cfg) {
    if(!str)return;
    let len = str.length;
    let canvas = document.createElement("canvas");
    canvas.width = 24 * len;
    canvas.height = 32;
    const drawingContext = canvas.getContext("2d");
    drawingContext.fillStyle = "#000000";
    drawingContext.globalAlpha = 
    cfg?.globalAlpha !== undefined?cfg.globalAlpha:0.1;
    drawingContext.fillRect(0, 0, canvas.width, 32);
    drawingContext.globalAlpha = 1;
    drawingContext.fillStyle = color;
    drawingContext.font = "24px Georgia";
    drawingContext.textAlign = "center";
    drawingContext.fillText(str, canvas.width/2, 24);
    let map = new THREE.CanvasTexture(canvas);
    let sprite;
    if(!this.namePanel){
      sprite = new THREE.Sprite(
        new THREE.SpriteMaterial({ map: map, color: "#ffffff" })
      )
    }
    else{
      sprite = this.namePanel;
      sprite.material.map.dispose();
      sprite.material.map = map;
    }
    sprite.position.set(0, height, 0);
    sprite.scale.set(90 * len, 120, 1);
    sprite.material.depthWrite = false;
    if(this.obj)this.obj.add(sprite);
    this.namePanel = sprite;
    return sprite;
  }

  add_hand_to_char(){
    if(!this.obj)return;
    let sprite = new THREE.Sprite();
    sprite.scale.set(600,600,1);
    this.obj.add(sprite);
    sprite.position.set(0,2500,0);
    if(player.handMat == undefined){
      player.handMat = new THREE.SpriteMaterial();
      player.handMat.depthWrite = false;
      player.handMat.depthTest = false;
      let texLoader = new THREE.TextureLoader();
      texLoader.load(player.handTexPath,tex=>{
        player.handMat.map = tex;
        sprite.material = player.handMat;
      });
    }
    else{
      sprite.material = player.handMat;
    }
    return sprite;
  }

  playClip(name,loop,clamp){
    //return;
    if(!this.bLoad){
      this._loadAnimModel(name);
      return;
    }
    let modelIdx = this.info.modelIdx;
    let cfg = player.cfg;
    let idx = -1;
    if(cfg && cfg.modelClips && cfg.modelClips[modelIdx]){
      let modelClips = cfg.modelClips[modelIdx];
      idx = isNaN(modelClips[name])?-1:modelClips[name];
    }
    new Promise((resolve,reject)=>{
      if(this.preb.clips && this.preb.clips[name]){
        resolve(this.preb.clips[name]);
      }
      else if(player.clips[idx]){
        resolve(player.clips[idx]);
      }
      else if(player.cfg && player.cfg.clips[idx]){
        let url = player.cfg.clips[idx];
        if(!player.loadQueue[url]){
          player.loadQueue[url]=[];
          player.loadQueue[url].push(resolve.bind(this));
        }
        else{
          player.loadQueue[url].push(resolve.bind(this));
          return;
        }
        let loader = new FBXLoader();
        loader.load(url,temp=>{
          if(temp.animations && temp.animations.length > 0){
            player.clips[idx] = temp.animations[0];
            if(player.loadQueue[url]){
              player.loadQueue[url].forEach(res=>{
                if(res)res(player.clips[idx]);
              })
              delete player.loadQueue[url];
            }
          }
        });
      }
    }).then(clip=>{
      if(!this.mixer)return;
      let action = this.mixer.clipAction(clip, this.role);
        if(action == this.curAction)return;
        if (this.curAction) {
          this.curAction.crossFadeTo(action, 0.2);
          if(this.curAction.loop == THREE.LoopRepeat){
            this.lastAction = this.curAction;
          }
        }
        action.enabled = true;
        this.curAction = action;
        // AnimationAction.timeScale = 1; //默认1，可以调节播放速度
        if (loop) {
            action.loop = THREE.LoopRepeat; //不循环播放
        }
        else {
          action.time = 0;
          action.paused = false;
          action.loop = THREE.LoopOnce;
          action.clampWhenFinished=clamp;
        }
        // if(loop){
        //   this.setState(name);
        // }
        action.play();//播放动画
    })
  }

  setSit(sit){
    if(sit){
      let data = sit.name?sit:this.o2.get_sit(sit);
      if(data){
        this.sit = sit.name?sit.name:sit;
        if(this.obj){
          this.obj.visible = true;
          this.obj.position.set(data.pos.x,data.pos.y,data.pos.z);
          this.sitAngle = (data.angle * 3.1416) / 180;
          //console.log(this.sitAngle);
          this.obj.rotation.set(0,this.sitAngle,0);
          this.setState('sit');
        }
      }
    }
    else{
      this.sit = undefined;
      this.setState('idle');
    }  
  }
  setState(name){
    if(this.state != name){
      this.state = name;
      if(this.onStateChg){
        this.onStateChg.forEach(e=>{
          e(name);
        })
      }
      this.playClip(name,true);
    } 
  }

  setHeadTexSize(size){
    if(this.head){
      if(this.head.material && this.head.material.map){
        let tex = this.head.material.map;
        let canvas = document.createElement('canvas');
        canvas.width = size;
        canvas.height = size;
        const cxt = canvas.getContext('2d');
        cxt.drawImage(tex.image,0,0,size,size);
        let url = canvas.toDataURL('image/jpg');
        let texLoader = new THREE.TextureLoader();
        let newMap = texLoader.load(url);
        URL.revokeObjectURL(tex.image.src);
        tex.dispose();
        this.head.material.map = newMap;
      }
    }
  }

  save(){
      let url = this.o2.szrUrl + "/save";
      let str = JSON.stringify(this.info);
      let auth_from = new FormData();
      auth_from.append('info',str);
      auth_from.append('account_id',this.id);
      auth_from.append('project_id',this.o2.project_id);
      fetch(url,{
          'method':'POST',
          'body':auth_from
      }).then(res=>{
        res.json().then(json=>{
          console.log(json);
        })
      })
  }

  removeObj(){
    if(this.role){
      if(this.mixer){
        this.mixer.stopAllAction();
        this.mixer.uncacheRoot(this.role);
        this.mixer = undefined;
      }
      if(this.head){
        if(this.head.material.map != this.preb.head.material.map){
          this.head.material.map?.dispose();
        }
        this.head.geometry.dispose();
        this.head.material.dispose();
      }
      if(this.body){
        this.body.traverse(e=>{
          if(e.material)e.material.dispose();
        })
      }
      if(this.hair){
        this.hair.traverse(e=>{
          if(e.material && e.name.indexOf('Hair') != -1)
            e.material.dispose();
        })
      }
      this.obj.remove(this.role);
      this.role = null;
    }
    this.obj.visible = false;
  }

  destroy(){
    console.log("destroy player:",this.name)
    if(this.role){
      if(this.mixer){
        this.mixer.stopAllAction();
        this.mixer.uncacheRoot(this.role);
        this.mixer = undefined;
      }
      if(this.head){
        if(this.head.material.map != this.preb.head.material.map){
          this.head.material.map?.dispose();
        }
        this.head.geometry?.dispose();
        this.head.material?.dispose();
      }
      if(this.body){
        this.body.traverse(e=>{
          if(e.material){
            e.geometry?.dispose();
            e.material?.dispose();
          }
        })
      }
      if(this.hair){
        this.hair.traverse(e=>{
          if(e.material && e.name.indexOf('Hair') != -1)
            e.geometry?.dispose();
            e.material?.dispose();
        })
      }
      //this.o2.removeObj
      //this.role.parent = null;
      this.role = null;
    }
    if(this.dicAnimObj){
      for (const key in this.dicAnimObj) {
        let id = this.dicAnimObj[key].instanceId;
        if(id !== undefined){
          this.o2.insMgr.remove(this.dicAnimObj[key]);
        }
      }
    }
    if(this.namePanel){
      this.namePanel.parent?.remove(this.namePanel);
      this.namePanel.material.map?.dispose();
      this.namePanel.material?.dispose();
      this.namePanel = null;
    }
  }

  _loadAnimModel(name){
    new Promise((resolve,reject)=>{
      let modelIdx = this.info.modelIdx;
      if(Number.isNaN(modelIdx))return;
      if(player.dicAnimModel[name] && player.dicAnimModel[name][modelIdx]){
        resolve(player.dicAnimModel[name][modelIdx]);
      }
      else if(player.cfg && player.cfg[name + "Models"]){
        if(!player.dicAnimModel[name])
        player.dicAnimModel[name]={};
        let url = player.cfg[name + "Models"][modelIdx];
        if(url){
          //if(!player.loadQueue)player.loadQueue = {};
          if(!player.loadQueue[url]){
            player.loadQueue[url]=[];
            player.loadQueue[url].push(resolve.bind(this));
          }
          else{
            player.loadQueue[url].push(resolve.bind(this));
            return;
          }
          this.o2.import_object_url(url).then(obj=>{
            player.dicAnimModel[name][modelIdx] = obj;
            if(player.loadQueue[url]){
              player.loadQueue[url].forEach(res=>{
                if(res)res(obj);
              })
              delete player.loadQueue[url];
            }
          })
        }
      }
      else{
        resolve();
      }
    }).then(obj=>{
      if(!this.dicAnimObj)this.dicAnimObj = {};
      if(!this.dicAnimObj[name] && obj){
        let animObj =document.o2.insMgr?
        document.o2.insMgr.create(obj):obj.clone();
        this.dicAnimObj[name] = animObj;
        this.obj.add(animObj);
      }
      if(this.curAnimObj)this.curAnimObj.visible = false;
      this.curAnimObj = this.dicAnimObj[name];
      if(this.curAnimObj)this.curAnimObj.visible = true;
    })
  }

  _loadModel(type,idx,action){
    let preb = null;
    if(player.dicPreb[type] && player.dicPreb[type][idx])
      preb = player.dicPreb[type][idx];
    if(preb){
        if (!preb.obj) {
            if(!player.onModelLoad[idx]){
                player.onModelLoad[idx] = [];
            }
            player.onModelLoad[idx].push((preb) => {
                if(action)action(preb);
            });
        }
        else{
            if(action)action(preb);
        }
    }
    else if(player.cfg.models &&
            idx < player.cfg.models.length &&
            type < player.cfg.models[idx].length){
        let url = player.cfg.models[idx][type];
        if(url && url != ''){
            preb = {};
            //preb.clips = {};
            if(!player.dicPreb[type])player.dicPreb[type] = {};
            player.dicPreb[type][idx] = preb;
            console.log(url);
            let promise = this.o2.import_object_url(url);
            promise.then((obj3) => {
                console.log('人物:',obj3);
                if(!obj3)return;
                preb.obj = obj3;
                preb.role = obj3;
                if(preb.obj == preb.role){
                  preb.obj = new THREE.Object3D();
                  preb.obj.type = 'Group';
                }
                obj3.children.forEach(e => {
                    if(e.material){
                        e.material.vertexColors = THREE.NoColors;
                    }
                    if(obj3.type != 'Group' && e.type== 'Group'){
                        preb.role = e;
                    }
                });
                for (let i = 0; i < this.o2.skeletons.length; i++) {
                  let skeleton = this.o2.skeletons[i];
                  if(skeleton.obj == preb.role){
                    preb.clips = skeleton.clips;
                    break;
                  }
                }
                if(player.onModelLoad[idx]){
                    player.onModelLoad[idx].forEach(e => {
                        e(preb);
                    });
                    player.onModelLoad[idx] = undefined;
                }
                if(action)action(preb);
            });
        }
    }
  }

  _setModel(idx,action){
    this._loadModel(this.modelType,idx,preb=>{
      if (preb) {
        this.preb = preb;
        let pos;
        let rot;
        if(this.obj){
          pos = this.obj.position;
          rot = this.obj.rotation;
        }
        // if(!player.baseBone){
        //   player.baseBone = new THREE.ObjectLoader().parse(baseBone);
        //   let root = player.baseBone;
        //   player.dicBone = {};
        //   player.dicBone[root.name] = root;
        //   getBoneArray(root,player.dicBone);
        //   player.boneInverses = {};
        //   for (const key in dicBone) {
        //     let mat = new THREE.Matrix4();
        //     let node = dicBone[key];
        //     while(node){
        //       mat = node.matrix.clone().multiply(mat);
        //       node = node.parent;
        //     }
        //     player.boneInverses[key] = mat;
        //   }
        // }
        let temp = this._cloneObj(preb);
        let newObj = temp.obj;
        let newRole = temp.role;
        if(pos && rot){
          newObj.position.set(pos.x,pos.y,pos.z);
          newObj.rotation.set(rot.x,rot.y,rot.z);
        }
        if(this.dicAcc){
          for (const key in this.dicAcc) {
            let acc = this.dicAcc[key];
            this._bindBone(acc);
            if(this.role)this.role.remove(acc);
            if(newRole)newRole.add(acc);
          }
        }
        if(this.hair){
          this._bindBone(this.hair);
          if(this.role) this.role.remove(this.hair);
          if(newRole) newRole.add(this.hair);
        }
        if(this.namePanel){
          if(this.obj) this.obj.remove(this.namePanel);
          if(newObj) newObj.add(this.namePanel);
        }
        if(this.obj && this.obj.parent){
          let node = this.obj.parent;
          node.remove(this.obj);
          node.add(newObj);
        }
        this.addAnimator(this.role,newRole);
        this.obj = newObj;
        this.role = newRole;
        this.role.visible = true;
        if(action)action();
      }
    })
  }



  _setBodyColor(rgb) {
    if(this.body){
      this.body.traverse(e=>{
        if(e.material){
          e.material.color.set(rgb);
        }
      })
    }
  }
  _setHairColor(rgb){
    if(this.hair){
      this.hair.traverse(e=>{
        if(e.material && e.name.indexOf('Hair') != -1){
          e.material.color.set(rgb);
        }
      })
    }
  }
  _setFaceColor(rgb){
    if(this.head){
      this.head.traverse(e=>{
        e.material.color.set(rgb);
      })
    }
  }

  _setHair(idx,action){
    new Promise((resolve,reject)=>{
      if(idx == 0){
        resolve(null);
      }
      else if(player.dicHairPreb[idx - 1]){
        resolve(player.dicHairPreb[idx - 1])
      }
      else if(player.cfg.hairModels){
        let url = player.cfg.hairModels[idx - 1];
        if(url && url != ''){
            this.o2.import_object_url(url).then(preb=>{
                player.dicHairPreb[idx - 1] = preb;
                resolve(preb)
            });
        }  
        else if(action){
          action();
        }
      }
    }).then(preb=>{
      if(this.hair && this.hair.parent){
        this.hair.parent.remove(this.hair);
        this.hair.traverse(e=>{
          if(e.material)e.material.dispose();
        })
        this.hair = null;
      }
      // if(this.role){
      //   this.role.visible = true;
      // }
      if(!preb){
        if(action)action();
        return;
      }
      this.hair = this._cloneAcc(preb);
      if(this.hair){
        if(this.role)this.role.add(this.hair);
        this.hair.children.forEach(e=>{
          if(e.type == 'SkinnedMesh'){
            let mtl = e.material.clone();
            if(this.info.hairColor){
              mtl.color.set(this.info.hairColor);
            }
            e.material = mtl;
          }
        })
      }
      if(action)action();
    })
  }

  _setAcc(idx,type,action) {
    new Promise((resolve,reject)=>{
      if(idx == 0){
        this._clearAcc(type);
        if(action)action();
      }
      else if(player.dicAccPreb[idx - 1]){
          resolve(player.dicAccPreb[idx - 1]);
      }
      else{
          let url = player.cfg.accModels[idx - 1];
          if(url){
              this.o2.import_object_url(url).then(preb=>{
                  player.dicAccPreb[idx - 1] = preb;
                  resolve(preb);
              }); 
          }
          else if(action){
            action();
          }
      }
    }).then(preb=>{
      if(!preb){
        if(action)action();
        return;
      }
      let acc = this._cloneAcc(preb);
      if(acc){
        if(this.dicAcc[type] && this.role){
          this.role.remove(this.dicAcc[type])
        }
        this.dicAcc[type] = acc;
        if(this.role){
          acc.visible = true;
          this.role.add(acc);
        } 
      }
      if(action)action();
    })
  }

  _setAvatarHead(md5,img,url,action){
    let scope = this;
    new Promise((resolve,reject)=>{
      //console.log(url);
      if(url){
        this._getAvatarData(url).then(data=>{
          resolve(data);
        },err=>{
          reject(err);
        })
      }
      else{
        this._load_avatar_data(md5,img).then(data=>{
          resolve(data);
        },err=>{
          reject(err);
        })
      }
    }).then(data=>{
      //生成顶点颜色文件
      // let loader = new FBXLoader();
      // loader.load('public/Head.fbx',obj=>{
      //   let head = obj.children[0];
      //   let colors = head.geometry.getAttribute('color');
      //   let count = colors.count;
      //   let arrValue = [];
      //   for (let i = 0; i < count; i++) {
      //     let v = colors.array[colors.itemSize * i];
      //     v = Math.floor(v * 1000) /1000;
      //     arrValue.push(v);
      //   }
      //   var a = document.createElement("a");
      //   var file = new Blob([JSON.stringify(arrValue)], {type: 'json'});
      //   a.href = URL.createObjectURL(file);
      //   a.download = 'vertexColor';
      //   a.dispatchEvent(new MouseEvent('click', {'bubbles': false, 'cancelable': true}));
      // });
      if(scope.headData && scope.headData.tex){
        scope.headData.tex.dispose();
      }
      scope.headData = data;
      if(scope.preb && scope.preb.head && scope.role){
        if(scope.head)scope.role.remove(scope.head);
        console.log(data);
        scope.head = scope._cloneHead(scope.head,data);
        scope.role.add(scope.head);
        if(action) action(null,scope);
      }
    },err=>{
      console.log(err);
      if(action) action(err,scope);
    })
  }

  _cloneHead(headPreb,data){
    if(headPreb){
      if(this.head){
        this.head.geometry.dispose();
      }
      let head = headPreb.clone();
      head.geometry = headPreb.geometry.clone();
      if(this.faceMtl){
        this.faceMtl.dispose();
      } 
      this.faceMtl = headPreb.material.clone();
      head.material.morphTargets = true;
      head.updateMorphTargets();
      head.material = this.faceMtl;
      if(this.info && this.info.faceColor){
        this._setFaceColor(this.info.faceColor);
      }
      let newPos = new THREE.Vector3();
      let pos = new THREE.Vector3();
      let vertices = head.geometry.getAttribute('position');
      let colors = head.geometry.getAttribute('color');
      let color = new THREE.Vector3();
      let posCount = vertices.count;
      let arrPos = [];
      if(data){
        if(posCount == data.vertices.count){
          for (let i = 0; i < data.vertices.count; i++) {
            newPos.fromBufferAttribute(data.vertices,i);
            pos.fromBufferAttribute(vertices,i);
            newPos.multiplyScalar(950);
            if(colors && colors.count == posCount){
              color.fromBufferAttribute(colors,i);
              pos.lerp(newPos,color.x);
            }
            else if(posCount == headWeight.length){
              pos.lerp(newPos,headWeight[i]);
            }
            arrPos.push(pos.x);
            arrPos.push(pos.y);
            arrPos.push(pos.z);
          }
          head.geometry.setAttribute('position',new THREE.Float32BufferAttribute(arrPos,3));
        }
        head.material.map = data.tex;
      }
      return head;
    }
  }

  _clearAcc(type){
    if(this.dicAcc[type]){
      this.role.remove(this.dicAcc[type]);
      this.dicAcc[type] = undefined;
    }
  }

  _cloneAcc(preb) {
    if(!preb) return;

    let acc = preb.clone(false);
    if(acc.type == "Group"){
      preb.children.forEach(e=>{
        if(e.type == 'SkinnedMesh'){
          acc.add(e.clone());
        }
      })
    }
    this._bindBone(acc);
    // preb.children.forEach(e => {
    //   if (e.type == "SkinnedMesh") {
    //     let obj = e.clone();
    //     let newArr = [];
    //     e.skeleton.bones.forEach(item => {
    //       newArr.push(this.dicBone[item.name]);
    //     });
    //     let arr = e.skeleton.boneInverses;
    //     obj.bind(new THREE.Skeleton(newArr, arr), e.bindMatrix);
    //     acc.add(obj);
    //   }
    // });
    return acc;
  }


  getBoneArray(bone, bonearr) {
    for (let i = 0; i < bone.children.length; i++) {
      let name = bone.children[i].name;
      if(!bonearr[name]){
        bonearr[name] = bone.children[i];
      }
      getBoneArray(bone.children[i], bonearr);
    }
  }
  _cloneObj(preb) {
    if(!preb)return;
    let newObj = preb.obj.clone(false);
    //newObj.visible = false;
    let newRole = newObj;
    if(preb.role != preb.obj){
      newRole = preb.role.clone(false);
      newObj.add(newRole);
      preb.obj.children.forEach(e => {
        if(e.type != 'Group' && e.type != 'SkinnedMesh'){
          newObj.add(e.clone());
        }
      });
    }
    for (let i = 0; i < preb.role.children.length; i++) {
      if (preb.role.children[i].type == "Bone") {
        let node = preb.role.children[i];
        this.preb.dicBone = {};
        this.preb.rootBone = node;
        this.preb.dicBone[node.name] = node;
        getBoneArray(node, this.preb.dicBone);
        this.dicBone = {};
        this.rootBone = node.clone();
        this.dicBone[node.name] = this.rootBone;
        getBoneArray(this.rootBone, this.dicBone);
        this.rootBone.updateWorldMatrix(true,true);
        break;
      }
    }
    newRole.add(this.rootBone);
    // if(rootBone && rootBone.name == player.baseBone.name){
    //   this.dicBone = {};
    //   rootBone = player.baseBone.clone();
    //   console.log(player.baseBone.matrix)
    //   this.dicBone[rootBone.name] = rootBone;
    //   getBoneArray(rootBone,this.dicBone);
    //   rootBone.updateWorldMatrix(true,true);
    // }
    // else{
    //   this.dicBone = dicBone;
    // }
    // if(this.dicBone){
    //   this.arrBone = [];
    //   const geometry = new THREE.SphereGeometry( 10, 32, 16 );
    //   const material1 = new THREE.MeshBasicMaterial( { color: 0xffff00 } );
    //   material1.depthTest = false;
    //   const material2 = new THREE.MeshBasicMaterial( { color: 0xff0000 } );
    //   const sphere1 = new THREE.Mesh( geometry, material1 );
    //   // const sphere2 = new THREE.Mesh( geometry, material2 );
    //   for (const key in this.dicBone) {
    //     this.arrBone.push(this.dicBone[key]);
    //     this.dicBone[key].add(sphere1.clone());
    //     // dicBone[key].add(sphere2.clone());
    //   }
    // }
    for (let j = 0; j < preb.role.children.length; j++) {
      let node = preb.role.children[j];
        if (node.type == "SkinnedMesh") {
          let skinmesh = node.clone();
          if(skinmesh.name.indexOf('Head') != -1){
            preb.head = node;
            //console.log(skinmesh);
            this.head = this._cloneHead(node,this.headData);
            skinmesh = this.head;
            // this._cloneHead(node,this.headData);
            // skinmesh.geometry = node.geometry.clone();
            // skinmesh.updateMorphTargets();
            // this.head = skinmesh;
            // this.faceMtl = node.material.clone();
            // this.head.material = this.faceMtl;
            // this.head.material.morphTargets = true;
            // if(this.faceColor)this.faceMtl.color = this.faceColor;
            // else this.faceColor = this.faceMtl.color;
          }
          else if(skinmesh.name.indexOf('Body') != -1){
            if(this.body){
              this.body.traverse(e=>{
                if(e.material)e.material.dispose();
              })
            }
            this.body = skinmesh;
            let bodyMtl = node.material.clone();
            skinmesh.material = bodyMtl;
            if(this.info && this.info.bodyColor){
              this._setBodyColor(this.info.bodyColor);
            }
          }
          let arrBone = [];
          node.skeleton.bones.forEach(item => {
            let bone = this.dicBone[item.name];
            arrBone.push(bone);
     
          });
          let arrMatrix = node.skeleton.boneInverses;
          skinmesh.bind(new THREE.Skeleton(arrBone,arrMatrix),skinmesh.bindMatrix);
          skinmesh.visible = true;
          newRole.add(skinmesh);
        }
        else if (node.type != "Bone") {
          let c = node.clone();
          newRole.add(c);
        }
    }
    return {obj:newObj,role:newRole};
  }

  _bindBone(obj){
    if(obj.type == 'SkinnedMesh'){
      let newArr = [];
      let arr = [];
      obj.skeleton.bones.forEach(item => {
        if(this.dicBone[item.name]){
          newArr.push(this.dicBone[item.name]);
        }
      });
      arr = obj.skeleton.boneInverses;
      obj.bind(new THREE.Skeleton(newArr, arr), obj.bindMatrix);
    }
    if(obj.children && obj.children.length > 0){
      obj.children.forEach(e=>{
        this._bindBone(e);
      })
    }
  }

  _combineMesh(){
    if(this.obj){
      if(this.accMesh){
        this.accMesh.geometry.dispose();
        this.obj.remove(this.accMesh);
        this.accMesh = null;
      }
      let vertices = [];
      let normals = [];
      let skinIndices = [];
      let skinWeights = [];
      // if(!this.boneInverses)
      //   this.boneInverses = [];
      let arrUV = [];
      let arrUV2 = [];
      let matrices = [];
      //console.log(this.arrBone);
      //let mat;
      // let temp = this.obj.matrix.clone();
      // this.obj.matrix = this.obj.matrix.identity ();
      console.log(this.obj.matrix);
      let pos = new THREE.Vector3();
      this.obj.children.forEach(c => {
          if(c.type == "SkinnedMesh" 
          && c.name.indexOf("Body") == -1
          && c.name.indexOf("Eye") == -1
          && c.name.indexOf("Hair") == -1){
            if(!this.combineMat){
              this.combineMat = new THREE.MeshBasicMaterial({map:c.material.map});
            }
            let poses = c.geometry.attributes.position.array;
            for (let i = 0; i < poses.length; i+=3) {
              pos.set(poses[i],poses[i+1],poses[i+2]);
              pos.applyMatrix4(c.matrix);
              vertices.push(pos.x);
              vertices.push(pos.y);
              vertices.push(pos.z);
            }
            let arrNormal = c.geometry.attributes.normal.array;
            arrNormal.forEach(e => {normals.push(e)});
            let uv = c.geometry.attributes.uv.array;
            uv.forEach(e => {
              arrUV.push(e);
              arrUV2.push(0);
            });
            // let uv2 = c.geometry.attributes.uv2.array;
            // uv2.forEach(e => {arrUV2.push(e)});
            //poses.forEach(e=>{vertices.push(e)});
            let bs = c.skeleton.bones;
            let idxs = c.geometry.attributes.skinIndex.array;
            idxs.forEach(e=>{
              if(e < bs.length){
                let bone = bs[e];
                let idx = this.arrBone.indexOf(bone);
                skinIndices.push(idx);
              }
            });
            let wgts = c.geometry.attributes.skinWeight.array;
            wgts.forEach(e=>{skinWeights.push(e)});
            let arrMatrix = c.skeleton.boneMatrices;
            arrMatrix.forEach(e => {
              matrices.push(e);
            });
            // let bms = c.bindMatrix;
            // bms.forEach(e=>{bindMatrix.push(e)});
            c.visible = false;
            c.material = this.combineMat;
            
          }
      });
      let geometry = new THREE.BufferGeometry();
      geometry.setAttribute("position",new THREE.Float32BufferAttribute(vertices,3));
      geometry.setAttribute("normal",new THREE.Float32BufferAttribute(normals,3));
      geometry.setAttribute( 'skinIndex', new THREE.Uint16BufferAttribute(skinIndices, 4 ) );
      geometry.setAttribute( 'skinWeight', new THREE.Float32BufferAttribute(skinWeights, 4 ) );
      geometry.setAttribute( 'uv', new THREE.Float32BufferAttribute(arrUV, 2 ) );
      geometry.setAttribute( 'uv2', new THREE.Float32BufferAttribute(arrUV2, 2 ) );
      let mesh = new THREE.SkinnedMesh(geometry,this.combineMat);
      mesh.bind(new THREE.Skeleton(this.arrBone));
      if(this.boneInverses && this.boneInverses.length == this.arrBone.length){
        mesh.skeleton.boneInverses = this.boneInverses;
      }
      this.accMesh = mesh;
      mesh.name = "combineMesh";
      this.obj.add(mesh);
      console.log(mesh);
      //this.obj.matrix = temp;
      //this.o2.play_skeleton_clip(this.obj,"ide",true);
    }
  }
  
  _load_avatar_data(md5,img,texSize=1024,isHigh=true){
    let url = this.o2.szrUrl + "/avatar";
    return new Promise((resolve,reject)=>{
        console.log('start load szr:',md5);
        let auth_from = new FormData();
        //auth_from.append('name',name);
        auth_from.append('lod',isHigh?0:7);
        auth_from.append('md5',md5);
        auth_from.append('texSize',texSize);
        if(img)auth_from.append('image',img);
        fetch(url,{
            'method':'POST',
            'body':auth_from
        }).then(res=>{
            res.json().then(json=>{
                console.log(json);
            if(json && json.ossUrl){
                this._getAvatarData(json.ossUrl).then(data=>{
                  resolve(data);
                })
            }
            else if(json.error){
                reject(json.error);
            }
        })
        })
    });
  }

  _getAvatarData(url){
    return new Promise((resolve,reject)=>{
      let xhr = new XMLHttpRequest();
      xhr.onload = ()=>{
          let status = xhr.status;
          if(status >=200 && status < 300){
              let zip = new JSZip;
              let data = {};
              //data.md5 = md5;
              zip.loadAsync(xhr.response).then(()=>{
                zip.file('model.jpg').async('blob').then(texBlob=>{
                    let texUrl = URL.createObjectURL(texBlob);
                    let texLoader = new THREE.TextureLoader();
                    // let canvas = document.createElement('canvas');
                    // let wh = this.modelType?256:1024;
                    // canvas.width = wh;
                    // canvas.height = wh;
                    // let img = new Image(wh,wh);
                    // img.src= texUrl;
                    // img.onload = ()=>{
                    //   let ctx = canvas.getContext("2d");
                    //   ctx.drawImage(img,0,0,wh,wh);
                    //   let imgData = ctx.getImageData(0,0,wh,wh);
                    //   for (let i = 0; i < imgData.data.length; i+=4) {
                    //     //let gray = 0.2989*imgData.data[i]+0.587*imgData.data[i+1]+0.114*imgData.data[i+2];
                    //     //gray *=1.2;
                    //     imgData.data[i] *=1;//gray;
                    //     imgData.data[i+1]*=1;//gray;
                    //     imgData.data[i+2]*=1;//gray;
                    //     imgData.data[i+3]=255;
                    //   }
                    //   ctx.putImageData(imgData,0,0);
                    //   data.tex = new THREE.CanvasTexture(canvas);
                    //   data.tex.needsUpdate = true;
                    //   URL.revokeObjectURL(texUrl);
                    //   if(data.tex && data.vertices) resolve(data);
                    // }
                    data.tex = texLoader.load(texUrl);
                    if(data.tex && data.vertices) resolve(data);
                })
                zip.file('model.json').async('string').then(str=>{
                  let model = JSON.parse(str);
                  let points = [];
                  let faces = model.faces;
                  let vertices = model.vertices;
                  //console.log(model);
                  if(faces && vertices){
                      let count = 0;
                      for (let i = 0; i < faces.length; i++) {
                          const f = faces[i] - 1;
                          if(vertices.length > f * 3 + 2){
                              points.push(vertices[f * 3]);
                              points.push(vertices[f * 3 + 1]);
                              points.push(vertices[f * 3 + 2]);
                              count++;
                          }
                      }
                      //console.log(points.length);
                      data.vertices = new THREE.BufferAttribute(new Float32Array(points),3,true);
                      data.faces = new THREE.BufferAttribute(new Uint16Array(faces),1,true);
                      if(data.tex && data.vertices) resolve(data);
                  }
                })
              })  
          }
          else{
            reject('load avatar data fail');
          }
      }
      if(url.includes("o2szr.oss-us-west-1.aliyuncs.com")){
        url = url.replace("o2szr.oss-us-west-1.aliyuncs.com","o2szr.oss-accelerate.aliyuncs.com");
      }
      console.log("avatar url:",url);
      xhr.open('GET', url);
      xhr.responseType = 'arraybuffer';
      xhr.send();
    })
  }

  _createClip(clip){
    //return clip;
    if(player.baseBone.name != this.rootBone.name){
      return clip;
    }
    let arr = clip.tracks;
    let tracks = [];
    for (let i = 0; i < arr.length; i++) {
      const e = arr[i];
      let arrStr = e.name.split('.');
      if(arrStr && arrStr.length == 2){
        let node = arrStr[0];
        let arrValue = [];
        let track = null;
        if(player.dicBone[node] && this.dicBone[node]){
          if(e.ValueTypeName == 'vector'){
            let baseVec = arrStr[1]=='position'?
            player.dicBone[node].position:player.dicBone[node].scale;
            let curVec = arrStr[1]=='position'?
            this.preb.dicBone[node].position:this.preb.dicBone[node].scale;
            let tempVec = new THREE.Vector3();
            for (let j = 0; j < e.values.length; j += 3) {
              tempVec.set(e.values[j],e.values[j+1],e.values[j+2]);
              tempVec.sub(baseVec).add(curVec);
              arrValue.push(tempVec.x);
              arrValue.push(tempVec.y);
              arrValue.push(tempVec.z);
              
            }
            track = new THREE.VectorKeyframeTrack(
              e.name,e.times,new Float32Array(arrValue)
            );
          }
          else if(e.ValueTypeName == 'quaternion'){
            let tempQtn = new THREE.Quaternion();
            for (let j = 0; j < e.values.length; j += 4) {
              tempQtn.set(e.values[j],e.values[j+1],e.values[j+2],e.values[j+3]);
              //tempQtn = player.dicBone[node].quaternion.clone().multiply(tempQtn.invert());
              tempQtn.multiply(player.dicBone[node].quaternion.clone().invert());
              //tempQtn = this.dicBone[node].quaternion.clone().multiply(tempQtn);
              tempQtn.multiply(this.preb.dicBone[node].quaternion);
              arrValue.push(tempQtn.x);
              arrValue.push(tempQtn.y);
              arrValue.push(tempQtn.z);
              arrValue.push(tempQtn.w);
            }
            track = new THREE.QuaternionKeyframeTrack(
              e.name,e.times,new Float32Array(arrValue)
            );
          }
          else{
            track = e;
          }
        }
        if(track) tracks.push(track);
      }
    }
    let newClip = new THREE.AnimationClip(clip.name,clip.duration,tracks);
    return newClip;
  }
}

function initBaseBone(){
  player.baseBone = new THREE.ObjectLoader().parse(baseBone);
  let root = player.baseBone;
  player.dicBone = {};
  player.dicBone[root.name] = root;
  getBoneArray(root,player.dicBone);
}

function getBoneArray(bone, bonearr) {
  for (let i = 0; i < bone.children.length; i++) {
    let name = bone.children[i].name;
    if(!bonearr[name]){
      bonearr[name] = bone.children[i];
    }
    getBoneArray(bone.children[i], bonearr);
  }
}

player.initBaseBone = initBaseBone;

export { player }
